export default {
  tabtitle: "メールの管理",
  contentHeader:
    "メールのテンプレート作成・編集・送信設定ができます。\r\n送信対象：クラウンマークのついたメールテンプレート。",
  emailManagementName: "メールの管理名",
  deleteEmail: "メールの削除",
  deleteEmailMessage:
    "@:{'common.hightlightInfo'}を削除してもよろしいですか？<br>削除することのメールは元に戻すことができまん。",

  addManagement: "管理の追加",
  setAsCurrent: "現在使用中に設定する",
  edit: "変更",
  copy: "複製",
  delete: "削除",
  newEmailType: "新しいメールタイプ",
  emailMessage: "メールメッセージ",
  editor: "編集者",
  preview: "プレビュー",
  warningDel: "*変数を削除しないでください",
  save: "保存する",
  titlePopupConfirmAdd: "メールの管理名を保存する",
  msgConfirmSubmit:
    "メールテンプレートは正常に保存されました。\n現在使用中に設定しますか？",
  defaultOn: "する",
  defaultOff: "しない",
};
