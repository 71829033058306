import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import ResponseList from "@/models/ResponseList";
import BaseListRequestParam from "@/models/ListRequestParam";

const urlCompany = "/companies"
const urlMember = "/members"
const urlDownloadMemberCSV = "/members/download-csv";

export default class CompanyMemberRepository extends Repository {

  getCompanyMemberList: (
    companyId: string,
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    companyId,
    params = { page: 1, pageSize: 10, keyword: "", partner: "", plan: "", userRole: "" }
  ) => {
    const data = await axiosInstance.get<ResponseList<any>>(
      `${urlCompany}/${companyId}${urlMember}`,
      { params }
    );
    if (data) {
      return data.data;
    }
    return false;
  };

  downloadCompanyMemberCSV = async (
    companyId: string,
    params?: {
      isCsvExport: boolean,
      keyword: string,
    }) => {
    const { data } = await axiosInstance.get(
      `${urlCompany}/${companyId}${urlDownloadMemberCSV}`,
       { params }
      );
    if (data) {
      return data;
    }
    return false;
  };
}
