export default {
  participated: "参加者",
  invited: "招待中",

  optionCancelInvite: "招待を取り消す",
  
  titlePlaceHolderSearchBar: "顧客を検索する",
  titlePartnerName: "パートナー企業",
  titlePlanName: "プラン",
  titleUserRole: "ユーザー権限",

  textButtonDownloadCsv: "CVSとしてダウンロード",

  titleProfileSetting: "基本情報",
  titleColumnSymbol: "顧客氏名",
  titleColumnNextPayment: "企業名",
  titleColumnCreatedAt: "作成日",

  titleSymbol: "プロフィール画像",
  titleFamilyName: "氏名",
  titlePosition: "役職",
  titleTelephoneNumber: "電話番号",
  titleEmailAddress: "メールアドレス",
  titleAcountType: "アカウントの種類",

  editSymbol: "プロフィール画像の変更",

  editFamilyName: "氏名の変更",
  editPosition: "役職の変更",
  editTelephoneNumber: "電話番号の変更",
  editEmailAddress: "メールアドレスの変更",
  editCompanyName: "企業名の変更",
  editAcountType: "アカウントの種類",

  editPositionLabel: "役職",
  editTelephoneNumberLabel: "電話番号",
  editEmailAddressLabel: "メールアドレス",
  editCompanyNameLabel: "企業名",

  buttonShowDetail: "詳細を見る",
  buttonDelete: "削除",
  logoCamera: "画像のアップロード",
  deletelogo:"画像削除",
  deleteTitle: "メンバーを削除",
  deleteMessage:
    "@:{'common.hightlightInfo'}を削除してもよろしいですか。<br/>メンバーは完全にアカウントにアクセスできなくなります。",
  cancelSendTitle: "招待の取り消し",
  buttonCancelSend: "取り消す",
  cancelMessage:
    "@:{'common.hightlightInfo'}への招待を<br>取り消してもよろしいですか？",
  resendInvite: "招待メールを再送する",
  reSendMessage:
    "@:{'common.hightlightInfo'}に招待メールを<br>再送してもよろしいですか。",
  resendInfo:
    "@:{'common.hightlightEmail'}に確認メールが送信されました。<br>受信トレイを確認し、メールに記載の手順に従って<br>変更を完了してください。",

  role1: "オーナー",
  role2: "マネージャー",
  role3: "メンバー",
};
