import { MessageFunction } from "vue-i18n";

type NeededUnionType<T extends any[]> = T[number];

const hightlightInfo: any = ({
  named,
}: NeededUnionType<Parameters<MessageFunction>>) =>
  `<span class="name-hightlight">${named("name")}
  ${named("email") ? "（" + named("email") + "）" : ""}</span>`;

const hightlightEmail: any = ({
  named,
}: NeededUnionType<Parameters<MessageFunction>>) =>
  `<span class="name-hightlight">${named("email")}</span>`;

export default {
  back: "前に戻る",
  cancel: "取り消す",
  next: "次へ",
  edit: "編集",
  pickTemplate: "雛形から書類を選択",
  select: "選択する",
  upload: "新しく書類(PDF)をアップロード",
  uploadFile: "ドラッグアンドドロップしてファイルをアップロード",
  uploadedFiles: "アップロードされたファイル",
  addTo: "追加する",
  download: "ダウンロード",
  firstNameHint: "姓",
  lastNameHint: "名",
  email: "メールアドレス",
  changeTo: "変更する",
  createTo: "作成する",
  sendTo: "送信する",
  optionalField: "（任意）",
  passwordHintCharacter: "1文字以上の英字",
  passwordHintNumber: "１文字以上の数字",
  passwordHintMin: "8文字以上",
  requiredText: "【必須】",
  updateSuccess: "正常に更新されました",
  error: "Error",
  delete: "削除する",
  hightlightInfo,
  hightlightEmail,
  myAccount: "マイアカウント",
  logout: "ログアウト",
  undoChanges: "変更を取り消す",
  complete: "完了する",
  sort: "並び替え",
  advancedSearch: "高度な検索",
  all: "全て",
  createdDate: "作成日",
  ascendingOrder: "昇順",
  descendingOrder: "降順",
  statisticInfo: "統計情報",
  confirm: "確認する",
  square: "正方形",
  circle: "円形",
  on: "オン",
  off: "オフ",
  uploadImage: "画像をアップロードする",
  copy: "_copy",
};
