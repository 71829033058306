export default {
  admin: "管理者",
  tabParticipated: "参加者",
  tabInviting: "招待中",
  searchTemplate: "管理者を検索する",
  downloadCsv: "CVSとしてダウンロード",
  addAdmin: "管理者を追加する",
  deleteAdmin: "管理者を削除",
  contractList: "氏名",
  permission: "付与する権限を選択",
  browsing: "閲覧",
  update: "更新",
  delete: "削除",
  edit: "編集",
  resendInvite: "招待メールを再送する",
  titlePopup: "管理者編集",
  signerName: "管理者氏名",
  lastName: "姓",
  firstName: "名",
  name: "名",
  mailAddress: "メールアドレス",
  lstCompany: "企業",
  emailManagement: "メールの管理",
  emailPending: "確認中の新しいメール",
  resendConfirmEmail: "確認メールをを再送する",
  cMindManagement: "C-mind社請求書管理",
  templateManagement: "雛形の管理",
  customerList: "顧客一覧",
  accountManagement: "管理者アカウント管理",
  wizPaymentManagement: "Wiz社お支払通知書管理",
  change: "変更する",
  add: "追加する",
  countPermission: "つの権利",
  deleteMessage:
    "@:{'common.hightlightInfo'}を削除してもよろしいですか。<br/>管理者は完全にアカウントにアクセスできなくなります。",
  cancelSend: "招待の取り消し",
  cancelMessage:
    "@:{'common.hightlightInfo'}への招待を<br>取り消してもよろしいですか？",
  reSendMessage:
    "@:{'common.hightlightInfo'}に招待メールを<br>再送してもよろしいですか。",
  resendInfo:
    "@:{'common.hightlightEmail'}に確認メールが送信されました。<br>受信トレイを確認し、メールに記載の手順に従って<br>変更を完了してください。",
  fullAuthority: "全体管理の権限を付与する",
  listOfPartners: "パートナー一覧",
};
