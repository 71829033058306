import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import BaseListRequestParam from "@/models/ListRequestParam";
import ResponseList from "@/models/ResponseList";

const urlCompany = "/companies";
const urlMemo = "/memos";

export default class MemoRepository extends Repository {
  getMemoList: (
    companyId: string,
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    companyId,
    params = {
      page: 1,
      pageSize: 10,
    }
  ) => {
    const { data } = await axiosInstance.get(
      `${urlCompany}/${companyId}${urlMemo}`,
      { params }
    );
    if (data) {
      return data;
    }
    return false;
  };

  createMemo = async (formValues: any, companyId: any) => {
    const { data } = await axiosInstance.post(
      `${urlCompany}/${companyId}${urlMemo}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateMemo = async (formValues: any, companyId: any, memoId: any) => {
    const { data } = await axiosInstance.put(
      `${urlCompany}/${companyId}${urlMemo}/${memoId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  deleteMemo = async (companyId: any, memoId: any) => {
    const data = await axiosInstance.delete(
      `${urlCompany}/${companyId}${urlMemo}/${memoId}`
    );
    if (data) {
      return data;
    }
    return false;
  };
}
