import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import ResponseList from "@/models/ResponseList";

const urlImageFile = `/image-files`

export default class ImageFileRepository extends Repository {
  getListImageFile = async (page = 1, pageSize = 10) => {
    const data = await axiosInstance.get<ResponseList<any>>(
      `${urlImageFile}?page=${page}&pageSize=${pageSize}`
    );
    if (data) {
      return data;
    }
    return false;
  };
  
  createImageFile = async (formValues: FormData) => {
    const { data } = await axiosInstance.post(
      `${urlImageFile}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  deleteImageFile = async (id: any) => {
    const data = await axiosInstance.delete(
      `${urlImageFile}/${id}`,
    );
    if (data) {
      return data;
    }
    return false;
  };
}
