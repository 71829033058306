import type { MutationTree } from "vuex";
import type { AccountState } from "./state";
const mutations: MutationTree<AccountState> = {
  getMyProfile(state, account: AccountState) {
    if (account.id != undefined) {
      state.id = account.id;
    }
    if (account.email != undefined) {
      state.email = account.email;
    }
    if (account.lastName != undefined) {
      state.lastName = account.lastName;
    }
    if (account.firstName != undefined) {
      state.firstName = account.firstName;
    }
    if (account.isMasterAdmin != undefined) {
      state.isMasterAdmin = account.isMasterAdmin;
    }
    if (account.templateManagement != undefined) {
      state.templateManagement = account.templateManagement;
    }
    if (account.companyAndMemberManagement != undefined) {
      state.companyAndMemberManagement = account.companyAndMemberManagement;
    }
    if (account.emailManagement != undefined) {
      state.emailManagement = account.emailManagement;
    }
    if (account.adminManagement != undefined) {
      state.adminManagement = account.adminManagement;
    }
    if (account.partnerManagement != undefined) {
      state.partnerManagement = account.partnerManagement;
    }
    if (account.partnerCompanyId != undefined) {
      state.partnerCompanyId = account.partnerCompanyId;
    }
    if (account.createdAt != undefined) {
      state.createdAt = account.createdAt;
    }
    if (account.emailPending != undefined) {
      state.emailPending = account.emailPending;
    }
  },
};

export default mutations;
