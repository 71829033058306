import { AnonymousFunction } from "@/models/Function";
import PlanInfo from "@/models/PlanInfo";

const on = "無効";
const off = "有効";

const titleNotificationConfirmSwitch: AnonymousFunction = ({ named }) =>
  `<span class="name-hightlight">${named("name")}</span>のプランのトライアルを<span class="plan-type-hightlight">${
    named("type") ? on : off
  }</span>にしてよろしいでしょうか？<br/>次のお支払いは来月更新されます。`;

const titleNotificationConfirmDowngradeToFreePlan: AnonymousFunction = ({ named }) =>
  `<span class="name-hightlight">${named("name")}</span>を${named("planName")}プランから
   <span class="plan-type-hightlight">フリープラン</span> にしてよろしいでしょうか？`;

export enum ContactType {
  CustomerSurvey = 0,
  MonthlyReport  = 1,
  ReleaseMail    = 2, 
}

export const getContactTypeName = (type: ContactType): string => {
  switch (type) {
    case ContactType.CustomerSurvey:
        return CustomerSurveyName;
    case ContactType.ReleaseMail:
        return ReleaseMail;
    default:
        return "";
  }
}

const CustomerSurveyName = "お客様アンケート";
const ReleaseMail = "アップデートのお知らせ";
const notificationOn = "送信する";
const notificationOff = "送信しない";

const titleCustomerContactConfirm: AnonymousFunction = ({ named }) =>
  `<span class="name-hightlight">${named("contactTypeName")}</span>を
  <span class="plan-type-hightlight">${named("isSendEmail") ? notificationOn : notificationOff}</span> に切り替えますか？`;

export default {
  deleteCompany: "企業を削除する",
  deleteCompanyWaring:
    "企業は完全に削除されます。削除すると元に戻すことができません。",
  planFree: "フリー",
  planStandard: "スタンダード",
  planTrial: "トライアル",
  planLite: "ライト",
  planOemFree: "OEMフリー",
  planOem1: "OEM1",
  planOem2: "OEM2",
  planOemLite: "OEMライト",
  planOemStandard: "OEM" + "スタンダード",
  planOemFlatFree: "OEMフラットフリー",
  planOemFlatLite: "OEMフラットライト",
  planOemFlatStandard: "OEMフラットスタンダード",
  companyInfo: "企業情報",
  latestPaymentStatus: "最終支払い完了日",
  paymentStatus: "支払ステータス",
  success: "決済完了",
  failed: "決済失敗",
  memberNumber: "メンバー数",
  LtdCompany: "株式会社",
  contractNumber: "契約書数",
  fileNumber: "ファイル数",
  completedContract: "総締結数",
  completedContractThisMonth: "当月締結数",
  countCreatedFromTemplate: "雛形からの契約書作成数",
  countContractInformation: "詳細情報数",
  countFreeItem: "自由項目数",
  countFreeText: "フリーテキスト数",
  countTextLink: "テキストリンク数",
  folderNumber: "フォルダ数",
  userNumber: "ユーザ数",
  groupNumber: "グループ数",
  templateNumber: "雛形数",
  contactNumber: "顧客数",

  titleListCompanies: "企業一覧",

  deleteCompanyMessage:
    "@:{'common.hightlightInfo'}を削除してもよろしいですか？<br>削除すると元に戻すことができません。",

  cantDeleteCompanyUntilFinishPayment: "支払いを完了するまでは削除できません",

  bannerTableDetail: "企業情報の設定",
  titleNotificationConfirmSwitch,

  titlePlaceHolderSearchBar: "企業を検索する",
  textButtonDownloadCsv: "CVSとしてダウンロード",
  titleColumnSymbol: "企業名",
  titleColumnSchedule: "プラン",
  titleColumnRepresentativeName: "代表者氏名",
  titleColumnNextPayment: "次の支払い",

  textButtonToggleChangePlan: "プラン変更",
  textButtonShowDetail: "詳細を見る",
  textButtonDelete: "削除",

  titlePopupSwitchPlan: "切り替え計画",

  editConfirmButton: "変更する",

  titleSymbol: "企業ロゴ",
  titleSymbolEdit: "企業のロゴの変更",
  titleCompanyName: "企業名",
  titlePosition: "企業住所",
  titlePhone: "電話番号",
  titlePresent: "代表者氏名",
  titlePartnerName: "パートナー企業",
  titleSellerName: "紹介営業担当者",
  titleClientPhone: "管理番号",
  titleStampImage: "オリジナル印影",
  titleStampImageEdit: "オリジナル印影の変更",
  titleStampImageDelete:"画像削除",

  titlePlanTrial: "トライアル",
  titleDowngradeToFreePlan: "ダウングレード",
  titlePaymentMethod: "支払い方法",
  titleInvoicePaymentMethod: "請求書支払",

  titleSwitchPlan: "プラン",
  descriptionSwitchPlan: "プランを切り替える",

  dropdownOEMTerminationOption: "フリー",
  dropdownnOEM1Option: "OEM1",
  dropdownnOEM2Option: "OEM2",

  errorWhenSwitchToOEMPlan: "OEMプラン タイプに切り替えることができません",
  errorSwitchToInvoicePaymentMethod: "請求書支払に切り替えることができません",

  editCompanyName: "企業名の変更",
  editCompanyNameLabel: "企業名",

  editPartnerCompanyName: "パートナー会社の変更",
  editPartnerCompanyNameLabel: "パートナー会社",

  editReferralCompanyName: "紹介営業担当者の変更",

  editRepresentativeName: "代表者氏名の変更",

  editPhoneNumber: "電話番号の変更",
  editPhoneNumberLabel: "電話番号",

  editControlNumber: "管理番号の変更",
  editControlNumberLabel: "管理番号",

  editSymbol: "企業ロゴを変更",
  editAddress: "企業住所の変更",
  editPostalCodeLabel: "【必須】郵便番号",
  editPrefectureLabel: "【必須】都道府県",
  editCityLabel: "【必須】市区町村",
  editAddressLabel: "【必須】丁目、番地",
  editBuildingRoomLabel: "(任意)建物名、部屋番号",

  userEvent: "ユーザーイベント",
  gmo: "GMO",
  gmoId: "GMO ID",
  gmoBillingStatus: "GMO 課金状態を確認する",
  checkUserEventOfCompany: "所属するユーザーのイベントを確認する",
  changeTrialPlan: "トライアルを切り替える",
  downgradeToFreePlan: "フリープランに切り替える",
  btnDowngradeToFreePlan: "切り替える",
  titleNotificationConfirmDowngradeToFreePlan,
  
  gmoTitle: "GMO課金状態",
  gmoDate: "日時",
  gmoFee: "料金",
  gmoStatus: "状態",
  gmoPaid: "支払い済み",
  gmoUnpaid: "未払い",
  noItem: "購入履歴はございません。<br/>「GMO課金状態」はお支払いが発生したお客様の「決済完了」「決済失敗」のデータが表示されます。",

  contractsTitle: "締結履歴",
  contractsLabel: "過去の締結数を確認する",
  contractsNoItem: "締結履歴はございません。",
  contractsDate: "日時",
  contractsNumber: "締結数",

  customerContactTitle: "顧客連絡（通知・配信）の設定",
  changeCustomerContact: "顧客連絡を設定する",
  titleCustomerContactType: "メールの種類",
  CustomerSurveyName,
  ReleaseMail,
  titleCustomerContactNotification: "通知可否",
  titleCustomerContactConfirm,
  notificationOn,
  notificationOff,

  memoTitle: "メモ一覧",
  memo: "メモ",
  memoWriter: "作成者",
  memoLabel: "メモを確認する",
  memoAddButton: "メモを作成する",
  memoNoItem: "メモの履歴はございません。",

  memoEditTitle: "メモの変更",
  memoAddTitle: "メモの追加",
  memoEditLabel: "メモ",

  memoDeleteTitle: "メモを削除する",
  memoDeleteMessage: "メモを削除してもよろしいですか？<br/>削除すると元に戻すことができません。",

  memberList: "メンバー一覧",
  memberListTitle: "所属するメンバーを確認する",
  memberPlaceHolderSearchBar: "所属メンバーの検索",
  memberTitleColumnSymbol: "氏名",
  memberTitleColumnAccountType: "アカウントの種類",
  memberTitleColumnCreatedAt: "作成日",

  monthlyReportTitle: "月次レポート",
  monthlyReportLabel: "月次レポートを確認する",
  monthlyReportNoItem: "月次レポートはございません。",
  monthlyReportDate: "年月",
  monthlyReportSignedTime: "平均締結時間",
  monthlyReportCreateContract: "契約書作成数",
  monthlyReportAverageCountSigner: "平均署名者数",
};
