import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import ResponseList from "@/models/ResponseList";
import BaseListRequestParam from "@/models/ListRequestParam";
import company from "@/locales/ja/company";

const urlCompanies = "/companies";
const urlUpdateLogo = `${urlCompanies}/logo`;
const urlUpdateName = `${urlCompanies}/name`;
const urlUpdatePhoneNumber = `${urlCompanies}/phone-number`;
const urlUpdateControlNumber = `${urlCompanies}/control-number`;
const urlUpdateReferralCompanyName = `${urlCompanies}/referral-company`;
const urlUpdateCompanyAddress = `${urlCompanies}/address`;
const urlUpdateRepresentativeName = `${urlCompanies}/representative-name`;
const urlUpdateReferralPersonName = `${urlCompanies}/referral-person-name`;
const urlUpdateImprint = `${urlCompanies}/imprint`;
const urlSwitchToTrialOn = `${urlCompanies}/switch-to-trial-on`;
const urlSwitchToTrialOff = `${urlCompanies}/switch-to-trial-off`;
const urlDowngradeToFreePlan = `${urlCompanies}/downgrade-to-free-plan`;
const urlDownloadCompanyCSV = `${urlCompanies}/download-csv`;
const urlCompanySimple = `${urlCompanies}/simple`;
const urlCustomerContact = `${urlCompanies}/customer-contacts`;
const urlUpdateOemPlan = `${urlCompanies}/update-oem-plan`;
const urlTurnOnInvoicePayment = `${urlCompanies}/turn-on-invoice-payment`;
const urlTurnOffInvoicePayment = `${urlCompanies}/turn-off-invoice-payment`;
const urlMonthlyReport = `/monthly-report`;


export default class CompanyRepository extends Repository {
  getListCompanies: (
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    params = { page: 1, pageSize: 100, keyword: "" }
  ) => {
    const { data } = await axiosInstance.get<ResponseList<any>>(urlCompanies, {
      params,
    });
    if (data) return data;
    return false;
  };

  getCompany = async (companyId: string) => {
    const { data } = await axiosInstance.get(`${urlCompanies}/${companyId}`);
    if (data) return data;
    return false;
  };

  getCompanySimple = async (companyId: string) => {
    const { data } = await axiosInstance.get(`${urlCompanySimple}/${companyId}`);
    if (data) return data;
    return false;
  };

  updateCompanyLogo = async (formValues: FormData, companyId: string) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateLogo}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyImprint = async (formValues: FormData, companyId: string) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateImprint}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyName = async (formValues: FormData, companyId: string) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateName}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyPhoneNumber = async (
    formValues: FormData,
    companyId: string
  ) => {
    const { data } = await axiosInstance.put(
      `${urlUpdatePhoneNumber}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyControlNumber = async (
    formValues: FormData,
    companyId: string
  ) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateControlNumber}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateReferralCompanyName = async (
    formValues: FormData,
    companyId: string
  ) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateReferralCompanyName}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyAddress = async (formValues: FormData, companyId: string) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateCompanyAddress}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateRepresentativeName = async (
    formValues: FormData,
    companyId: string
  ) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateRepresentativeName}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateReferralPersonName = async (
    formValues: FormData,
    companyId: string
  ) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateReferralPersonName}/${companyId}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  deleteCompany = async (companyId: string) => {
    try {
      const data = await axiosInstance.delete(`${urlCompanies}/${companyId}`);
      if (data) {
        return data;
      }
      return false;
    } catch (e: any) {
      return e;
    }
  };

  switchToTrialOff = async (companyId: string) => {
    const data = await axiosInstance.post(
      `${urlSwitchToTrialOff}/${companyId}`
    );
    if (data) {
      return data;
    }
    return false;
  };

  switchToTrialOn = async (companyId: string) => {
    let data = null;
    try {
      data = await axiosInstance.post(
        `${urlSwitchToTrialOn}/${companyId}`
      );
    } catch {
      return false;
    }
    return data;
  };

  updateOemPlan = async (companyId: string, oemPlanType: string) => {
    let data = null;
    try {
      data = await axiosInstance.post(
        `${urlUpdateOemPlan}/${companyId}?oemPlanType=${oemPlanType}`
      );
    } catch {
      return false;
    }
    return true;
  }

  downloadCompanyCSV = async (params?: { keyword: string }) => {
    const { data } = await axiosInstance.get(`${urlDownloadCompanyCSV}`, {
      params,
    });
    if (data) {
      return data;
    }
    return false;
  };

  /** start: customer contact */

  updateCustomerContact = async (companyId: string, contactType: number) => {
    try {
      const data = await axiosInstance.put(`${urlCustomerContact}/${companyId}?type=${contactType}`);
      if (data == null) {
        return true;
      }
      return false;
    } catch (e: any) {
      return e;
    }
  };

  updateCustomerContacts = async (companyIds: Array<string>, contactType: number) => {
    try {
      const param = JSON.stringify({companyIds})
      const data = await axiosInstance.put(`${urlCustomerContact}?type=${contactType}`, param);
    } catch (e: any) {
      return false;
    }
    return true
  };

  deleteCustomerContact = async (companyId: string, contactType: number) => {
    try {
      const data = await axiosInstance.delete(`${urlCustomerContact}/${companyId}?type=${contactType}`);
      if (data == null) {
        return true;
      }
      return false;
    } catch (e: any) {
      return e;
    }
  };

  deleteCustomerContacts = async (companyIds: Array<string>, contactType: number) => {
    try {
      const param = JSON.stringify({contactType, companyIds})
      const data = await axiosInstance.delete(`${urlCustomerContact}?type=${contactType}`, { data: param });
      if (data == null) {
        return true;
      }
      return false;
    } catch (e: any) {
      return e;
    }
  };

  turnOnInvoicePayment = async (companyId: string) => {
    const data = await axiosInstance.post(
      `${urlTurnOnInvoicePayment}/${companyId}`
    );
    return data;
  };

  turnOffInvoicePayment = async (companyId: string) => {
    const data = await axiosInstance.post(
      `${urlTurnOffInvoicePayment}/${companyId}`
    );
    return data;
  };

  /** end: customer contact */

  downgradeToFreePlam = async (companyId: string) => {
    let data = null;
    try {
      data = await axiosInstance.post(
        `${urlDowngradeToFreePlan}/${companyId}`
      );
    } catch (e: any) {
      return e;
    }
    return data;
  };

  getMonthlyReportList: (
    companyId: string,
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    companyId,
    params = {
      page: 1,
      pageSize: 10,
    }
  ) => {
    const { data } = await axiosInstance.get(
      `${urlCompanies}/${companyId}${urlMonthlyReport}`,
      { params }
    );
    if (data) {
      return data; 
    }
    return false;
  };
}
