import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import "./validation";

createApp({
  render: () => h(App),
})
  .use(i18n)
  .use(router)
  .mount("#app");
