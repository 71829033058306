import menuAdmin from "@/models/menu-admin";
import { computed, ComputedRef, watch } from "vue";
import checkAfterLogin from "@/router/function";
import store from "@/store";

export default function useMenuList(): {
  menuList: ComputedRef<
    {
      path: string;
      name: string;
      localeText: string;
    }[]
  >;
  isMenuListReady: ComputedRef<boolean>;
  onMenuListReady: (onReadyCallback: (isReady: boolean) => void) => void;
} {
  checkAfterLogin();
  const myProfile = computed(() => store.state.account);

  const menuList = computed(() => [
    ...(myProfile.value.templateManagement > 0 ? [menuAdmin.Template] : []),
    ...(myProfile.value.companyAndMemberManagement > 0 ? [menuAdmin.Company] : []),
    ...(myProfile.value.companyAndMemberManagement > 0 ? [menuAdmin.Member] : []),
    ...(myProfile.value.emailManagement > 0 ? [menuAdmin.Email] : []),
    ...(myProfile.value.adminManagement > 0 ? [menuAdmin.AdminManagement] : []),
    ...(myProfile.value.partnerManagement > 0 ? [menuAdmin.Partner] : []),
  ]);

  const isMenuListReady = computed(() => menuList.value.length > 0);

  const onMenuListReady: (
    onReadyCallback: (isReady: boolean) => void
  ) => void = (onReadyCallback) => {
    watch(() => isMenuListReady.value, onReadyCallback);
  };

  return {
    menuList,
    isMenuListReady,
    onMenuListReady,
  };
}
