import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import BaseListRequestParam from "@/models/ListRequestParam";
import ResponseList from "@/models/ResponseList";

const urlCompany = "/companies"
const urlContracts = "/contracts"

export default class ContractsRepository extends Repository {

  getContractsList: (
    companyId: string,
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    companyId,
    params = {
      page: 1,
      pageSize: 20,
    }
  ) => {
    const { data } = await axiosInstance.get(
      `${urlCompany}/${companyId}${urlContracts}`,
      { params }
    );
    if (data) {
      return data;
    }
    return false;
  };
};