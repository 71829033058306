import common from "./common";
import errors from "./errors";
import notification from "./notification";
import login from "./login";
import template from "./template";
import sidebarAdmin from "./sidebar-admin";
import email from "./email";
import adminManagement from "./admin-management";
import company from "./company";
import member from "./member";
import cmind from "./cmind";
import wiz from "./wiz";
import partner from "./partner";
import imageFile from "./image-file";

export default {
  common,
  errors,
  notification,
  login,
  template,
  sidebarAdmin,
  email,
  adminManagement,
  company,
  member,
  cmind,
  wiz,
  partner,
  imageFile,
};
