import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";

const urlRequestForgotPasswordCode = "/request-forgot-password-code";
const urlUpdatePassword = "/update-password-by-forgot-password-code";

export default class ForgotPasswordRepository extends Repository {
  requestForgotPasswordCode = async (formValues: FormData) => {
    const data = await axiosInstance.post(
      `${urlRequestForgotPasswordCode}`,
      formValues
    );
    if (data) {
      return true;
    }
    return false;
  };

  updateForgotPassword = async (formValues: FormData) => {
    const data = await axiosInstance.post(`${urlUpdatePassword}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };
}
