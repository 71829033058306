import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import ResponseList from "@/models/ResponseList";
import BaseListRequestParam from "@/models/ListRequestParam";

const urlInvoice = `/invoices`;
const urlGmo = `${urlInvoice}/gmo`;

export default class CompanyRepository extends Repository {
  getListOfGMOInvoices: ( companyId: string, params: BaseListRequestParam ) => 
  Promise<false | ResponseList<any>> = async (companyId, params = { page: 1, pageSize: 10, }) => {
    const data = await axiosInstance.get(`${urlGmo}/${companyId}`, { params });
    
    if (data) {
      return data.data;
    }
    return [];
  };
}