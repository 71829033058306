import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";

const urlEmail = "/emails";
const urlListEmailType = `${urlEmail}/types`;
const urlSetDefaultEmailTemplate = `${urlEmail}/set-default`;

export default class CompanyRepository extends Repository {
  getListEmailType = async () => {
    const { data } = await axiosInstance.get(`${urlListEmailType}`);
    if (data) return data;
    return false;
  };

  getEmailTemplate = async (templateId: string) => {
    const { data } = await axiosInstance.get(`${urlEmail}/${templateId}`);
    if (data) return data;
    return false;
  };

  createEditEmailTemplate = async (payload: any, isEdit: boolean) => {
    let temp: any;
    if (!isEdit) {
      temp = await axiosInstance.post(`${urlEmail}`, payload);
    } else {
      temp = await axiosInstance.put(`${urlEmail}`, payload);
    }
    const { data } = temp;
    if (data) return data;
    return false;
  };

  setDefaultEmailTemplate = async (emailTemplateId: string) => {
    try {
      const { data } = await axiosInstance.post(
        `${urlSetDefaultEmailTemplate}/${emailTemplateId}`
      );
      if (data) return data;
    } catch (e: any) {
      return {
        error: e.data.message
      };
    }
    return false;
  };

  deleteEmailTemplate = async (emailTemplateId: string) => {
    const data = await axiosInstance.delete(`${urlEmail}/${emailTemplateId}`);
    if (data) return data;
    return false;
  };
}
