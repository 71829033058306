export class AccountState {
  id = "";
  email = "";
  lastName = "";
  firstName = "";
  isMasterAdmin = false;
  templateManagement = 0;
  companyAndMemberManagement = 0;
  emailManagement = 0;
  adminManagement = 0;
  partnerManagement = 0;
  partnerCompanyId = "";
  createdAt = "";
  emailPending = "";
}
