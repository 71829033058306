import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";

const urlInvoices = "/invoices";
const urlSettings = "/settings";

export default class CmindWizRepository extends Repository {
  getInvoices = async (company = "", year = "") => {
    const { data } = await axiosInstance.get(
      `${urlInvoices}/${company}?year=${year}`
    );
    if (data) return data;
    return {};
  };

  getSetting = async (company = "") => {
    const { data } = await axiosInstance.get(`${urlSettings}/${company}`);
    if (data) return data;
    return {};
  };

  updateSetting = async (company = "", payload: any) => {
    const { data } = await axiosInstance.put(
      `${urlSettings}/${company}`,
      payload
    );
    if (data) {
      return data;
    }
    return false;
  };

  //   updateCompanyImprint = async (formValues: FormData) => {
  //     const { data } = await axiosInstance.put(`${urlUpdateImprint}`, formValues);
  //     if (data) {
  //       return data;
  //     }
  //     return false;
  //   };
}
