import { AdminManagementRepository, RepositoryFactory } from "@/lib/https";
import store from "@/store";
import { computed } from "vue";

const { getProfile } =
  RepositoryFactory.getRepository<AdminManagementRepository>(
    AdminManagementRepository
  );

export default function checkAfterLogin() {
  async function getMyProfile() {
    try {
      const response = computed(() => store.state.account);
      if (!response.value.id) {
        const myProfile = await getProfile();
        if (!myProfile) return;
        store.commit("account/getMyProfile", {
          id: myProfile.id,
          email: myProfile.email,
          lastName: myProfile.lastName,
          firstName: myProfile.firstName,
          isMasterAdmin: myProfile.isMasterAdmin,
          templateManagement: myProfile.templateManagement,
          companyAndMemberManagement: myProfile.companyAndMemberManagement,
          emailManagement: myProfile.emailManagement,
          adminManagement: myProfile.adminManagement,
          partnerManagement: myProfile.partnerManagement,
          partnerCompanyId: myProfile.partnerCompanyId,
          createdAt: myProfile.createdAt,
          emailPending: myProfile.emailPending,
        });
      }
    } catch (e) {
      localStorage.clear();
    }
  }
  getMyProfile();

  return {
    getMyProfile,
  };
}
