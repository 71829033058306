import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import BaseListRequestParam from "@/models/ListRequestParam";
import ResponseList from "@/models/ResponseList";

const urlPartnerCompanies = "/referral-companies";
const urlDistributionRatio = "/sales-distribution-ratio";

export default class PartnerRepository extends Repository {
  getPartnerList: (
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    params = {
      page: 1,
      pageSize: 10,
    }
  ) => {
    const { data } = await axiosInstance.get<any>(urlPartnerCompanies, { params });
    if (data) {
      return data;
    }
    return false;
  };

  getPartnerEmail = async (referralCompanyId: string) => {
    const { data } = await axiosInstance.get(`${urlPartnerCompanies}/${referralCompanyId}/emails`);
    if (data) return data;
    return false;
  };

  getPartnerRate = async () => {
    const { data } = await axiosInstance.get(`${urlDistributionRatio}`);
    if (data) return data;
    return false;
  };

  updatePartnerRate = async (partnerRate: string) => {
    const { data } = await axiosInstance.put(`${urlDistributionRatio}`, partnerRate);
    if (data) {
      return data;
    }
    return false;
  };

  updatePartnerEmail = async (formValues: any, referralCompanyId: any) => {
    const { data } = await axiosInstance.put(
      `${urlPartnerCompanies}/${referralCompanyId}/emails`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };
}
