export default {
  loginTagline: "現場の声から生まれた電子契約サービス",
  loginTitle: "Digital Signにログイン",
  loginForgotPassword: "パスワードを忘れた場合は",
  loginCreateAccount: "アカウントを作成する。",
  login: "ログイン",
  password: "パスワード",
  authenticate: "認証する",

  forgotPassTagline: "FORGET PASSWORD",
  forgotPassTitle: "パスワードのリセット",
  forgotPassText: "登録したメールアドレスを入力してください。",
  forgotPassCancelBtn: "キャンセル",

  forgotPassConfirmText:
    "にメールを送信しました。 <br/>メールに記載の手順に従って再設定を行ってください。",
  forgotPassConfirmBtn: "ログインに戻る",

  signupTagline: "CREATE ACCOUNT",
  signupTitle: "アカウントを作成する",
  signupPasswordConfirm: "パスワードを認証する",
  signupLoginText: "既にアカウントをお持ちの方は",
  signupLogin: "こちら",
  signupEmailConfirmText:
    "確認メールが<span>{email}</span>に送信されました<br>受信トレイを確認し、基本登録を完了することを確認してください。",

  emailConfirmTagline: "EMAIL CONFIRMED",
  emailConfirmTitle: "メールアドレスが正常にが確認されました",
  emailConfirmText:
    "メールアドレスが確認されました。<br/>以下のボタンよりパスワードの再設定に進んでください。",
  emailConfirmButton: "パスワードを再設定する",

  passwordNewTagline: "NEW PASSWORD",
  passwordNewTitle: "パスワードの再設定",
  passwordNewHint: "新しいパスワード",
  passwordNewRetypeHint: "新しいパスワードの再入力",
  passwordNewButton: "再設定",

  passwordNewSuccessMessage: "新しいパスワードを使用してログインしてください。",
  passwordNewSuccessButton: "ログインに戻る",
  returnLogin: "ログインに戻る",
};
