import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";

const urlOrganizationProvinces = "/prefectures";
const urlOrganizationPreferences = "/referral-companies";

export default class OnboardingRepository extends Repository {
  getProvinces = async (): Promise<Record<string, string>[]> => {
    const data = await axiosInstance.get<Record<string, string>[]>(
      `${urlOrganizationProvinces}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  getPreferences = async (): Promise<Record<string, string>[]> => {
    const data = await axiosInstance.get<Record<string, any>[string]>(
      `${urlOrganizationPreferences}`
    );
    if (data) {
      return data.data.data;
    }
    return [];
  };
}
