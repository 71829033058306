export default {
  publishedTab: "公開された雛形",
  unpublishedTab: "未公開の雛形",

  deleteTemplate: "雛形を削除",
  deleteMessage:
    "@:{'common.hightlightInfo'}を削除してもよろしいですか？<br>雛形は完全に削除されます",

  searchHint: "雛形を検索する",
  publishedTitle: "雛形",
  publish_status: "公開する",

  progressAddFile: "書類の追加",
  progressSigner: "署名者の設定",
  progressVars: "入力項目の設定",
  progressConfirm: "雛形の確認",
  progressDone: "完了",

  nextBtn: "次へ",
  backBtn: "前に戻る",
  downloadOpt: "ダウンロード",
  deleteOpt: "削除",
  add: {
    document: "書類の追加",
    textDisplay: "画像のアップロード",
    confirmBtn: "確認",
  },
  contract: {
    name: "雛形の契約書名",
    namePlaceholder: "CONTRACT A",
  },
  signers: {
    editPopupTitle: "署名者の設定の編集",
    document: "この書類の署名者",
    addBtn: "署名者の追加",
    security: "セキュリティ",
    emptyMessage: "「署名者の追加」を選択して、新しい署名者を追加します。",
    on: "オン",
    off: "オフ",
    requiredXID: "xIDの署名を要求",
    senderRequiredXID: "送信者にxIDの署名を要求する",
    twoFactor: "二段階認証を要求",
    needTimeStamp: "認定タイムスタンプを付与する",
  },
  vars: {
    addStamp: "印影追加",
    addText: "フリーテキスト追加",
    addCheckBox: "チェックボックス追加",
    placeHolder: "テキスト",
    placeHolderOptional: "テキスト",
    toggleButtonText1: "デフォルトでチェックを付ける",
    toggleButtonText2: "任意項目にする",
    selectPlaceHolder: "署名者を選んでください",
    fontSize: "文字サイズ",
  },
  summary: {
    templateLabel: "テンプレート",
    allowTransfer: "契約書の転送を許可する",
    signerList: "署名者一覧",
    noSigners: "追加された署名者はいませんでした",
  },
  done: {
    completed: "COMPLETED",
    templateCreated: "雛形の作成が完了しました",
    msg3: "雛形が正しく作成されました。",
    msg4: "この雛形はいつでも編集することができます。",
    confirmBtn: "確認",
  },

  addButton: "雛形を作成する",
  templateTitle: "雛形の契約書名",
  select: "選択",
  deleteBtn: "作業を中止して破棄",
  title: "雛形の管理",
  signer: "署名者",
  addPlaceholder: "プレースホルダーを入力してください",
};
