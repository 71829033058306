import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";

const urlCampaignDetail = "/campaign-detail";

export default class CampaignDetailRepository extends Repository {
  createCampaignDetail = async (contactType: number) => {
    try {
      const data = await axiosInstance.post(`${urlCampaignDetail}/${contactType}`);
      if (data) return true;
    } catch (e: any) {
      return e;
    }
    return false;
  };

  deleteCampaignDetail = async (contactType: number) => {
    try {
      const data = await axiosInstance.delete(`${urlCampaignDetail}/${contactType}`);
      if (data) return true;
    } catch (e: any) {
      return e;
    }
    return false;
  };
}
