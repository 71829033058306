import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import checkAfterLogin from "./function";
import { nextTick } from "vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/forgot-password",
    component: () => import("@/views/layouts/LoginLayout.vue"),
    children: [
      {
        path: "request-code",
        meta: { title: "Forgot Password" },
        name: "ForgotPassword",
        component: () => import("@/views/pages/login/ForgotPassword.vue"),
      },
      {
        path: "email-confirm",
        meta: { title: "Forgot Password Confirm" },
        name: "ForgotPasswordConfirm",
        component: () =>
          import("@/views/pages/login/ForgotPasswordConfirm.vue"),
      },
      {
        path: "email-confirmed",
        meta: { title: "Email Confirm" },
        name: "EmailConfirmed",
        component: () => import("@/views/pages/login/PasswordEmailConfirm.vue"),
      },
      {
        path: "password-new",
        meta: { title: "PasswordNew" },
        name: "PasswordNew",
        component: () => import("@/views/pages/login/PasswordNew.vue"),
        props: (route) => ({ code: route.query.code }),
      },
      {
        path: "password-new-success",
        meta: { title: "PasswordNewSuccess" },
        name: "PasswordNewSuccess",
        component: () => import("@/views/pages/login/PasswordNewSuccess.vue"),
      },
    ],
  },

  {
    path: "/login",
    component: () => import("@/views/layouts/LoginLayout.vue"),
    children: [
      {
        path: "",
        meta: { title: "Login Page" },
        name: "Login",
        component: () =>
          import(
            /* webpackChunkName: "Login" */ "@/views/pages/login/Login.vue"
          ),
      },
      {
        path: "/signup",
        meta: { title: "Sign Up" },
        name: "SignUp",
        component: () => import("@/views/pages/login/Signup.vue"),
        props: { isSentConfirmMail: false },
      },
      // {
      //   path: "sent-confirm-email",
      //   meta: { title: "Sent Confirm Email" },
      //   name: "SentConfirmEmail",
      //   component: () => import("@/views/pages/login/Signup.vue"),
      //   props: { isSentConfirmMail: true },
      // },
    ],
  },

  {
    path: "/",
    name: "AfterLoginAdminLayout",
    component: () => import("@/views/layouts/AfterLoginAdminLayout.vue"),
    async beforeEnter(to, from, next) {
      try {
        checkAfterLogin()
        next()
      } catch (e) {
        next({ name: "Login" });
      }

    },
    redirect: "adminManagement",
    children: [
      {
        // Template
        path: "/template/:id?",
        name: "Template",
        meta: { title: "Template" },
        component: () => import("../views/pages/template/Template.vue"),
        children: [
          {
            path: "",
            name: "TemplateList",
            component: () => import("../views/pages/template/TemplateList.vue"),
            props: true,
          },
          {
            path: "file-and-info",
            name: "TemplateFileAndInfo",
            meta: { title: "Template FileAndInfo" },
            component: () =>
              import("../views/pages/template/TemplateFileAndInfo.vue"),
            props: true,
          },
          {
            path: "signers",
            name: "TemplateSigner",
            meta: { title: "Template Signer" },
            component: () =>
              import("../views/pages/template/TemplateSigner.vue"),
            props: true,
          },
          {
            path: "vars",
            name: "TemplateVars",
            meta: { title: "Template Vars" },
            component: () => import("../views/pages/template/TemplateVars.vue"),
            props: true,
          },
          {
            path: "summary",
            name: "TemplateSummary",
            meta: { title: "Template Summary" },
            component: () =>
              import("../views/pages/template/TemplateSummary.vue"),
            props: true,
          },
          {
            path: "done",
            name: "TemplateDone",
            meta: { title: "Template Done" },
            component: () => import("../views/pages/template/TemplateDone.vue"),
            props: true,
          },
        ],
      },

      // Email
      {
        path: "email",
        name: "Email",
        meta: { title: "Email" },
        component: () => import("../views/pages/email/Email.vue"),
      },
      {
        path: "email/:emailTypeId",
        name: "EmailAdd",
        meta: { title: "Email Add" },
        component: () => import("../views/pages/email/EmailAdd.vue"),
        props: true,
      },
      {
        path: "email/:emailTypeId/:emailTemplateId",
        name: "EmailEdit",
        meta: { title: "Email Edit" },
        component: () => import("../views/pages/email/EmailAdd.vue"),
        props: true,
      },
      {
        path: "company",
        name: "Company",
        meta: { title: "Company" },
        component: () => import("../views/pages/company/Company.vue"),
      },
      {
        path: "company/:companyID",
        name: "CompanyDetail",
        meta: { title: "Company Detail" },
        component: () => import("../views/pages/company/CompanyDetail.vue"),
      },

      // MonthlyReport
      {
        path: "company/monthly-report/:id",
        name: "MonthlyReport",
        meta: { title: "Monthly Report" },
        component: () => import("@/views/pages/company/MonthlyReport.vue"),
      },
      
      // GMO
      {
        path: "company/gmo/:id",
        name: "GmoBillingStatus",
        meta: { title: "GMO Billing Status" },
        component: () => import("@/views/pages/gmo/GMO.vue"),
      },

      // Contracts
      {
        path: "company/contracts/:id",
        name: "Contracts",
        meta: { title: "Contracts" },
        component: () => import("@/views/pages/company/Contracts.vue"),
      },

      // Member
      {
        path: "company/member/:companyID",
        name: "CompanyMember",
        meta: { title: "Company Member" },
        component: () => import("@/views/pages/company/CompanyMember.vue"),
      },

      // Memo
      {
        path: "company/memo/:companyID",
        name: "Memo",
        meta: { title: "Memo" },
        component: () => import("@/views/pages/company/Memo.vue"),
      },

      {
        path: "member",
        name: "Member",
        meta: { title: "Member" },
        component: () => import("../views/pages/member/Member.vue"),
      },
      {
        path: "member/:memberID",
        name: "MemberDetail",
        meta: { title: "Member Detail" },
        component: () => import("../views/pages/member/MemberDetail.vue"),
        props: (route) => ({
          isInviting: route.query.isInviting,
          ...route.query,
        }),
      },
      {
        path: "cmind",
        name: "cMind",
        meta: { title: "Cmind" },
        component: () => import("../views/pages/cmind/Cmind.vue"),
      },
      {
        path: "wiz",
        name: "Wiz",
        meta: { title: "Wiz" },
        component: () => import("../views/pages/wiz/Wiz.vue"),
      },

      // Admin Management
      {
        path: "adminManagement",
        name: "AdminManagement",
        meta: { title: "Admin Management" },
        component: () =>
          import("../views/pages/adminManagement/AdminManagement.vue"),
      },
      {
        path: "adminManagement/my-account",
        name: "MyAccount",
        meta: { title: "My Account" },
        component: () => import("@/views/pages/MyAccount.vue"),
      },

      // User Event
      {
        path: "user-event/:companyID",
        name: "UserEvent",
        meta: { title: "User Event" },
        component: () => import("@/views/pages/member/UserEvent.vue"),
      },

      // Partner
      {
        path: "partner",
        name: "Partner",
        meta: { title: "Partner" },
        component: () => import("@/views/pages/partner/Partner.vue"),
      },
      {
        path: "partner/:partnerID",
        name: "PartnerDetail",
        meta: { title: "Partner Detail" },
        component: () => import("@/views/pages/partner/PartnerDetail.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title + "--DigitalSign" || "Digital Sign";
  });
});

export default router;
