export default {
  cmind: "株式会社C-mind",
  
  cmindInvoiceListTab: " 請求書リスト",
  cmindSettingTab: "C-mind設定",

  tableTitlePaymentNotice: "請求書名",
  tableTitleCmind: "C-mind設定",

  optionPaymentNoticePreview: "請求書のプレビュー",
  optionDownloadPaymentNotice: "請求書をダウンロードする",
  optionEdit: "編集",

  editCminOperatingExpenseTitlePopup: "営業費配分率を編集",
  editCminOperatingExpenseLabelPopup: "営業費配分率",
  editCminDevelopmentCostTitlePopup: "開発・運営費配分率を編集",
  editCminDevelopmentCostLabelPopup: "開発・運営費配分率",
  editCminEmailTitlePopup: "メールアドレスを編集",
  saleRate: "営業費配分率",
  serviceManagementRate: "開発・運営費配分率",
  thisYearInvoices: "年の請求書",
};
