import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import ResponseList from "@/models/ResponseList";
import BaseListRequestParam from "@/models/ListRequestParam";

const urlMembers = "/members";
const urlInviteMembers = "/member-invitations";
const urlPendingResend = `${urlInviteMembers}/invite/resend`;
const urlUpdateProfilePicture = "/members/profile-picture";
const urlUpdateFullName = "/members/full-name";
const urlUpdatePosition = "/members/position";
const urlUpdatePhoneNumber = "/members/phone-number";
const urlUpdateEmail = "/members/email";
const urlUpdateCompanyName = "/members/company-name";
const urlUpdateRole = "/members/role";
const urlDownloadMemberCSV = `${urlMembers}/download-csv`;

export default class MemberRepository extends Repository {

  getMemberList: ( params: BaseListRequestParam ) => 
    Promise<false | ResponseList<any>> = async (
      params = { page: 1, pageSize: 10, keyword: "", partner: "", plan: "", userRole: "" }) => {

      const data = await axiosInstance.get<ResponseList<any>>(`${urlMembers}`, { params });
      if (data) {
        return data.data;
      }
      return false;
  };

  getInviteMemberList = async (page = 1, pageSize = 10, keyword = "") => {
    const data = await axiosInstance.get<ResponseList<any>>(
      `${urlInviteMembers}?page=${page}&pageSize=${pageSize}&keyword=${keyword}`
    );
    if (data) {
      return data.data;
    }
    return false;
  };

  getMemberProfile = async (memberID: string | string[]) => {
    const data = await this.axios.get(`${urlMembers}/${memberID}`);
    if (data) {
      return data.data;
    }
    return false;
  };

  getInviteMemberProfile = async (memberID: string | string[]) => {
    const data = await this.axios.get(`${urlInviteMembers}/${memberID}`);
    if (data) {
      return data.data;
    }
    return false;
  };

  updateProfilePicture = async (memberID: string, formValues: FormData) => {
    const { data } = await axiosInstance.put(
      `${urlUpdateProfilePicture}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateFullName = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateFullName}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updatePosition = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdatePosition}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updatePhoneNumber = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdatePhoneNumber}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateEmail = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateEmail}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyName = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateCompanyName}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateRole = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateRole}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  pendingResend = async (memberID: string) => {
    const data = await this.axios.post(`${urlPendingResend}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  deleteMember = async (memberID: string) => {
    const data = await axiosInstance.delete(`${urlMembers}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  deleteInviteMember = async (memberID: string) => {
    const data = await axiosInstance.delete(`${urlInviteMembers}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  downloadMemberCSV = async (params?: { isCsvExport: boolean, keyword: string, partner: string, plan: string, userRole: string }) => {
    const { data } = await axiosInstance.get(`${urlDownloadMemberCSV}`, {
      params,
    });
    if (data) {
      return data;
    }
    return false;
  };
}
