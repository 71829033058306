import { defineRule } from "vee-validate";

// Install rules

defineRule("required", (value: string | string[] | number | number[]) => {
  if (!value) return "error_required";
  if (Array.isArray(value) && value.length == 0) return "error_required";

  return true;
});

defineRule("email", (value: string) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return "error_format_email";
  }
  return true;
});

defineRule("number", (value: string) => {
  if (!/^[0-9]+$/i.test(value)) {
    return "error_number";
  }
  return true;
});
