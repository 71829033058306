import axios from "axios";
import storage from "@/lib/storage/storageHelper";
import useMenuList from "@/lib/compositional-logic/useMenuList";

const baseDomain = process.env.VUE_APP_API_URL as string;

function authHeader(formData: boolean) {
  const localStorage = storage.getLocalStorage();
  const userToken = localStorage.get("userToken");
  const userAuthorize = localStorage.get("userTokenCSRF");
  if (userToken) {
    if (formData) {
      return {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": `${process.env.VUE_APP_URL as string}`,
        Authorization: `Bearer ${userToken}`,
      };
    }
    return {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": `${process.env.VUE_APP_URL as string}`,
      Authorization: `Bearer ${userToken}`,
    };
  }
  if (userAuthorize) {
    localStorage.remove("userTokenCSRF");
    return {
      "Content-Type": "application/json",
      "X-CSRF-Token": userAuthorize,
    };
  }
  return {
    "Content-Type": "application/json",
  };
}

const axiosInstance = axios.create({
  baseURL: baseDomain,
  responseType: "json",
  withCredentials: true,
});

/* eslint-disable no-param-reassign */
// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers =
      config.data instanceof FormData ? authHeader(true) : authHeader(false);
    return config;
  },
  (error) => Promise.reject(error)
);
/* eslint-enable no-param-reassign */

axiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (err.response && err.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    if (err.response && err.response.status === 403) {
      const { menuList, onMenuListReady } = useMenuList();
      onMenuListReady((value) => {
        if (value) window.location.pathname = menuList.value[0].path;
      });
    }
    return Promise.reject(err.response as string);
  }
);

export default axiosInstance;
