import Repository from "@/lib/https/repository";
import Template, { SimpleBuiltInTemplate } from "@/models/Template";
import ResponseList from "@/models/ResponseList";
import BaseListRequestParam from "@/models/ListRequestParam";

const urlTemplate = "/templates";
const urlTemplateFullInfo = `${urlTemplate}/full-information`;
const urlTemplatePublish = `${urlTemplate}/publish`;
const urlTemplateUnPublish = `${urlTemplate}/unpublish`;

export default class TemplateRepository extends Repository {
  getTemplateList: (
    params?: BaseListRequestParam & {
      unpublished?: boolean;
    }
  ) => Promise<false | ResponseList<SimpleBuiltInTemplate>> = async (
    params = {
      page: 1,
      pageSize: 10,
    }
  ) => {
    const { data } = await this.axios.get(urlTemplate, { params });
    if (data) return data;
    return false;
  };

  getTemplate: (id: string) => Promise<false | Template> = async (id) => {
    const { data } = await this.axios.get<Template>(
      `${urlTemplateFullInfo}/${id}`
    );
    if (data) return data;
    return false;
  };

  createTemplate: (formValues: FormData) => Promise<false | Template> = async (
    formValues
  ) => {
    const { data } = await this.axios.post<Template>(urlTemplate, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updateTemplate: (formValues: FormData) => Promise<false | Template> = async (
    formValues
  ) => {
    const { data } = await this.axios.put<Template>(urlTemplate, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  deleteTemplate: (id: string) => Promise<boolean> = async (id) => {
    try {
      await this.axios.delete(`${urlTemplate}/${id}`);
    } catch {
      return false;
    }
    return true;
  };

  publishTemplate: (id: string) => Promise<boolean> = async (id) => {
    const data = await this.axios.put(`${urlTemplatePublish}/${id}`);
    if (data) return true;
    return false;
  };

  unPublishTemplate: (id: string) => Promise<boolean> = async (id) => {
    const data = await this.axios.put(`${urlTemplateUnPublish}/${id}`);
    if (data) return true;
    return false;
  };
}
