const locale = "sidebarAdmin.";

export default {
  Template: {
    path: "template",
    name: "TemplateList",
    localeText: `${locale}template`,
  },

  Company: {
    path: "company",
    name: "Company",
    localeText: `${locale}company`,
  },

  Member: {
    path: "member",
    name: "Member",
    localeText: `${locale}member`,
  },

  Email: {
    path: "email",
    name: "Email",
    localeText: `${locale}email`,
  },

  AdminManagement: {
    path: "adminManagement",
    name: "AdminManagement",
    localeText: `${locale}adminManagement`,
    group: false,
  },

  CMind: {
    path: "cMind",
    name: "cMind",
    localeText: `${locale}cMind`,
    group: false,
  },

  Wiz: {
    path: "wiz",
    name: "Wiz",
    localeText: `${locale}wiz`,
    group: false,
  },

  Partner: {
    path: "partner",
    name: "Partner",
    localeText: `${locale}partner`,
    group: false,
  },
};
