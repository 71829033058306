export default {
  partnerListTab: " パートナー一覧",
  partnerDistributionTab: "パートナー分配",

  titleColumnSymbol: "パートナー名",
  titlePartnerDistribution: "分配管理",
  titlePartnerInfo: "基本情報",
  titleSaleRate: "営業",
  titleDevelopmentRate: "製品(開発)",
  titleServiceManagementRate: "運営",

  buttonShowDetail: "詳細を見る",

  ListPartner: "パートナー一覧",

  titleEmailAddress: "メールアドレス",
  editEmailAddress: "メールアドレスの変更",
  editEmailAddressLabel: "メールアドレス",
  addField: "追加",

  editSaleRate: "営業費配分率の変更",
  editSaleRateLabel: "営業費配分率",
  editDevelopmentRate: "製品(開発)費配分率の変更",
  editDevelopmentRateLabel: "製品(開発)費配分率",
  editServiceManagementRate: "運営費配分率の変更",
  editServiceManagementRateLabel: "運営費配分率",

  automaticCalculationGuide: "※自動計算されます",

  paymentNotification: "支払い通知書",
  paymentNotificationTitle: "お支払い通知書を確認する",
};
