export default {
  wiz: "株式会社Wiz",

  paymentNoticeListTab: "お支払通知書リスト",
  wizSettingTab: "Wiz社設定",

  tableTitlePaymentNotice: "お支払通知書名",
  tableTitleWiz: "Wiz設定",

  optionPaymentNoticePreview: "お支払通知書のプレビュー",
  optionDownloadPaymentNotice: "お支払通知書をダウンロードする",
  optionEdit: "編集",

  editWizOperatingExpenseTitlePopup: "営業費配分率を編集",
  editWizOperatingExpenseLabelPopup: "営業費配分率",
  editWizEmailTitlePopup: "メールアドレスを編集",
  thisYearCostReport: "年のお支払通知書",
};
