import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import BaseListRequestParam from "@/models/ListRequestParam";
import ResponseList from "@/models/ResponseList";
import Admin from "@/models/Admin";
import { AxiosResponse } from "axios";

const urlAdminList = "/admins";
const urlProfile = "/admins/profile";
const urlInviteAdmin = "/invitations";
const urlResendInviteAdmin = "/invitations/resend";
const urlCancelChangingAdminEmail = "/admins/cancel-changing-email";
const urlResendlChangingAdminEmail = "/admins/resend-changing-email";

export default class AdminManagementRepository extends Repository {
  getProfile: () => Promise<false | Admin> = async () => {
    const { data } = await axiosInstance.get<Admin>(urlProfile);
    if (data) {
      return data;
    }
    return false;
  };

  getAdminList: (
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    params = {
      page: 1,
      pageSize: 10,
      keyword: "",
    }
  ) => {
    const data = await axiosInstance.get(urlAdminList, { params });
    if (data) {
      return data.data;
    }
    return [];
  };

  updateAdmin = (payload: any): Promise<AxiosResponse<unknown>> =>
    axiosInstance.put(`${urlAdminList}`, payload);

  updateFullName = (form: FormData): Promise<AxiosResponse<unknown>> =>
    axiosInstance.put(`${urlAdminList}/full-name`, form);

  updateEmail = (form: FormData): Promise<AxiosResponse<unknown>> =>
    axiosInstance.put(`${urlAdminList}/email`, form);

  cancelChangingEmail = (): Promise<AxiosResponse<unknown>> =>
    axiosInstance.put(urlCancelChangingAdminEmail);

  resendChangingEmail = (): Promise<AxiosResponse<unknown>> =>
    axiosInstance.put(urlResendlChangingAdminEmail);

  deleteAdmin = async (adminID: string): Promise<boolean> => {
    try {
      await axiosInstance.delete(`${urlAdminList}/${adminID}`);
    } catch (error) {
      return false;
    }
    return true;
  };

  inviteAdmin = (payload: any): Promise<AxiosResponse<unknown>> =>
    axiosInstance.post(`${urlInviteAdmin}`, payload);

  getInviteAdminList: (
    params: BaseListRequestParam
  ) => Promise<false | ResponseList<any>> = async (
    params = {
      page: 1,
      pageSize: 10,
      keyword: "",
    }
  ) => {
    const data = await axiosInstance.get(urlInviteAdmin, { params });
    if (data) {
      return data.data;
    }
    return [];
  };

  getInviteAdminProfile = async (adminID: string | string[]) => {
    const data = await this.axios.get(`${urlInviteAdmin}/${adminID}`);
    if (data) {
      return data.data;
    }
    return false;
  };

  resendInviteAdmin = async (adminID: string, payload: any) => {
    const { data } = await axiosInstance.post(
      `${urlResendInviteAdmin}/${adminID}`,
      payload
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateAdminInvitation = async (payload: any) => {
    const { data } = await this.axios.put(`${urlInviteAdmin}`, payload);
    if (data) {
      return data;
    }
    return false;
  };

  deleteAdminInvitation = async (adminID: string) => {
    const data = await axiosInstance.delete(`${urlInviteAdmin}/${adminID}`);
    if (data) {
      return data;
    }
    return false;
  };
}
