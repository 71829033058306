import axiosInstance from "@/lib/https/setting";
import Repository from "@/lib/https/repository";
import LoginData from "@/models/LoginData";

const urlLoginByEmailPasword = "/login-by-email-password";
const urlSignUp = "/signup-by-email-password";
const urlVerifyEmail = "/auth/verify-email";
const urlLogout = "/logout";

export default class AuthRepository extends Repository {
  signUp = async (formValues: FormData) => {
    const data = await axiosInstance.post(urlSignUp, formValues);
    if (data) {
      return true;
    }
    return false;
  };

  loginByEmail = async (formValues: FormData) => {
    return await axiosInstance.post<LoginData>(
      urlLoginByEmailPasword,
      formValues
    );
  };

  verifyEmail = async (code: string) => {
    const data = await axiosInstance.get(`${urlVerifyEmail}?code=${code}`);
    if (data) {
      return data;
    }
    return false;
  };

  logout = async () => {
    await this.axios.post(urlLogout);
  };
}
